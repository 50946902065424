<template>
  <div class="settlement w-1920">
    <Loading v-if="isLoading" />
    <header-user></header-user>
    <settlement-search></settlement-search>
    <div class="settlement-title">
      <div class="settlement-content w-1200">
        <span>填写并核对订单信息</span>
      </div>
    </div>
    <div class="settlement-content w-1200">
      <settlement-consignee @currentAddress="setAddress"></settlement-consignee>
      <div class="settlement-content-line w-1200"></div>
      <!-- <settlement-payment></settlement-payment> -->
      <div class="settlement-delivery_method">
        <div class="title">
          <span>派送方式</span>
        </div>
        <div class="fangshi">
          <div class="content" v-for="(dict, index) in dictList" :key="index">
            <button class="settlement-button" 
              :class="deliveryMethod==dict.dictLabel?'active':''" @click="setDeliveryMethod(dict.dictLabel)">
              <span>{{dict.dictLabel}}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="settlement-content-line w-1200"></div>
      <settlement-detailed-list ref="settlementDetailedList" :data="data"></settlement-detailed-list>
      <el-input
        type="textarea"
        :rows="3"
        placeholder="备注"
        maxlength="1000"
        v-model="buyerRemark">
      </el-input>
      <settlement-discount
        ref="discount"
        v-if="jifen != 1"
      ></settlement-discount>
      <div class="settlement-real">
        <div class="pay-wrapper">
          <div v-if="jifen == 1">
            <div class="settlement-real-item">
              <span class="pay-title">总积分：</span
              ><span class="sum">{{ exchangeScore * num }}</span>
            </div>
            <div class="settlement-real-item">
              <span class="pay-title">实付积分：</span
              ><span class="sum">{{ exchangeScore * num }}</span>
            </div>
          </div>
          <div v-else>
            <div class="settlement-real-item">
              <span class="pay-title">总金额(含运费)：</span
              ><span class="sum">￥{{ sumPrice }}</span>
            </div>
            <div class="settlement-real-item">
              <span class="pay-title">优惠券抵扣：</span
              ><span class="sum">￥- {{ couponPrice }}</span>
            </div>
            <div class="settlement-real-item">
              <span class="pay-title">购物卡抵扣：</span
              ><span class="sum">￥- {{ cardPrice }}</span>
            </div>

            <div class="settlement-real-item">
              <span class="pay-title">应付款：</span
              ><span class="sum"
                >￥{{ sumPrice - couponPrice - cardPrice }}</span
              >
            </div>
          </div>
          <div class="settlement-info">
            <div class="address-top">
              <span class="pay-title">寄送至：</span>
              <span class="pay-text"
                >{{ address.receiverDistrict }} {{ address.receiverCity }}
                {{ address.receiverDistrict }}
                {{ address.receiverAddress }}</span
              >
            </div>
            <div class="address-bottom">
              <span class="pay-title">收货人：</span>
              <span class="pay-text"
                >{{ address.receiverName }} {{ address.receiverPhone }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <settlement_member ref="settlementMember" @selectMember="setMember" />
      <div class="button-content">
        <button class="submit" @click="submitOrder">提交订单</button>
      </div>
    </div>
    <!-- <baoyou></baoyou> -->
    <foot></foot>
  </div>
</template>

<script>
import header_user from '@/components/header';
import settlement_search from './settlement-search';
import settlement_consignee from './settlement-consignee';
import settlement_payment from './settlement-payment';
import settlement_detailed_list from './settlement-detailed-list';
import settlement_invoice from './settlement-invoice';
import settlement_discount from './settlement-discount';
import { toDecimal2 } from '@/utils/numberUtils.js';
import { submitOrder, getOrderTemp } from '@/api/order.js';
import { openPay } from '@/utils/pageUtils.js';
import { getMemberInfo } from '@/api/member.js';
import settlement_member from './settlement-member';
import { get, post } from '@/utils/request';

import baoyou from '@/components/order/baoyou';
import foot from '@/components/foot';

export default {
  components: {
    'header-user': header_user,
    'settlement-search': settlement_search,
    'settlement-consignee': settlement_consignee,
    'settlement-payment': settlement_payment,
    'settlement-detailed-list': settlement_detailed_list,
    'settlement-invoice': settlement_invoice,
    'settlement-discount': settlement_discount,
    settlement_member,
    baoyou: baoyou,
    foot: foot,
  },
  data() {
    return {
      skuIds: [],
      skuId: '',
      buyFrom: '',
      hanliang: '',
      address: {},
      data: [],
      price: 0,
      orderData: [],
      memberId: null,
      active: [],
      couponPrice: 0.0,
      sumPrice: 0,
      select: [],
      couponList: [],
      couponId: '',
      cardId: '',
      shopCard: [],
      cardPrice: 0,
      jifen: '',
      exchangeScore: 0,
      spuId: '',
      GukList: [],
      Gukactive: [],
      num: 0,
      orderSource: '1',
      isLoading: false,
      storePostage: 0,
      buyerRemark:'',
      deliveryMethod:'快递',//派送方式
      dictList:[{dictLabel:'自提'},{dictLabel:this.$t('common.Material_flow')},{dictLabel:'快递'}],//派送方式列表
    };
  },
  created() {
    this.jifen = this.$route.query.jifen;
    this.spuId = this.$route.query.spuId;
    // 获取临时订单信息
    getOrderTemp().then((res) => {
      console.log("11111",res.data)
      res.data.forEach((i) => {
        i.storePrice = 0;
        i.skuList.forEach((j) => {
          j.saleAttrCode = eval('(' + j.saleAttrCode + ')');
          if (j.value != '0mol/mol' && j.value != '0ppm') {
            // 格式化单位信息
            if (j.value.indexOf('mol/mol') != -1) {
              j.saleAttrCode.push({
                attrName: '确认含量',
                attrValue: j.value.replace(
                  'mol/mol',
                  'X10<sup>-6</sup>mol/mol'
                ),
              });
            } else {
              j.saleAttrCode.push({
                attrName: '确认含量',
                attrValue: j.value,
              });
            }
          }

          j.saleAttrCode.forEach((k, index) => {
            if (k.attrName == '浓度范围') {
              j.saleAttrCode.splice(index, 1);
            }
          });
        });

        for (let q = 0; q < i.skuList.length; q++) {
          for (let j = q + 1; j < i.skuList.length; j++) {
            if(i.skuList[q].remark =='1'&& i.skuList[j].remark=='1'){
              if (i.skuList[q].name == i.skuList[j].name&&i.skuList[q].storeId == i.skuList[j].storeId&&i.skuList[q].prodCatId == i.skuList[j].prodCatId&&i.skuList[q].batchNumber == i.skuList[j].batchNumber) {
                for (let p = 0; p < i.skuList[j].saleAttrCode.length; p++) {
                  if (
                    i.skuList[j].saleAttrCode[p].attrName == '多组分' ||
                    i.skuList[j].saleAttrCode[p].attrName == '气体组分' ||
                    i.skuList[j].saleAttrCode[p].attrName == '确认含量'
                  ) {
                    i.skuList[q].saleAttrCode.push(i.skuList[j].saleAttrCode[p]);
                  }
                }
              }
            }
          }
          var skuList = JSON.parse(JSON.stringify(i.skuList));
          for (let j = q + 1; j < i.skuList.length; j++) {
            if(i.skuList[q].remark =='1'&& i.skuList[j].remark=='1'){
              if (i.skuList[q].name == i.skuList[j].name&&i.skuList[q].storeId == i.skuList[j].storeId&&i.skuList[q].prodCatId == i.skuList[j].prodCatId&&i.skuList[q].batchNumber == i.skuList[j].batchNumber) {
                for(let k = 0; k < skuList.length; k++){
                  if (skuList[k].batchNumber == i.skuList[j].batchNumber&&skuList[k].skuId == i.skuList[j].skuId) {
                    skuList.splice(k, 1);
                  }
                }
              }
            }
          }
          i.skuList = skuList;
        }
        res.data.forEach((rew)=>{
          rew.skuList.forEach((rea)=>{
            if(rea.num==0){
              rea.num=1
              rew.storePrice+=rea.num*rea.price

            }
          })
        })
        for (let p = 0; p < i.skuList.length; p++) {
          i.storePrice += i.skuList[p].sumPrice;
        }

        if (i.storeFreePostage != null && i.storePostage != null) {
          if (i.storePrice < i.storeFreePostage) {
            i.storePrice = i.storePrice + i.storePostage;
            this.storePostage += i.storePostage;
          }
        }
        this.sumPrice += i.storePrice;
      });
      console.log(res.data)
      this.getjifen(res.data[0].skuList[0].skuId);
      this.skuId = res.data[0].skuList[0].skuId;
      this.num = res.data[0].skuList[0].num;
      this.$refs.discount.data = this.data = res.data;
    });
  },
  methods: {
    getjifen(e) {
      get(
        'api/storeCategory/getExchangeProductByProductId?productId=' + e + ''
      ).then((res) => {
        this.exchangeScore = res.data.data.exchangeScore;
      });
    },
    setcouponPrice() {
      this.couponPrice = this.$refs.discount.price;
      this.cardPrice = this.$refs.discount.cardPrice;
      this.price = toDecimal2(
        this.sumPrice * 1 - this.couponPrice * 1 - this.cardPrice * 1
      );
    },
    setMember(memberId) {
      this.memberId = memberId;
      this.doSubmitOrder();
    },
    setAddress(address) {
      this.address = address;
    },
    getSumPrice(price) {
      this.price = price;
    },
    submitOrder() {
      this.isLoading = true;
      if (this.jifen != 1) {
        //获取优惠券选择的数据
        this.active = this.$refs.discount.active;
        this.couponList = this.$refs.discount.couponList;
        var couponId = '';
        for (var i = 0; i < this.couponList.length; i++) {
          if (this.active[i]) {
            couponId =
              couponId == ''
                ? this.couponList[i].memberCouponRecordId + ''
                : couponId +
                  ',' +
                  (this.couponList[i].memberCouponRecordId + '');
          }
        }
        this.couponId = couponId;
        //获取购物卡数据 this.shopCard
        this.Gukactive = this.$refs.discount.Gukactive;
        this.GukList = this.$refs.discount.GukList;
        var cardId = '';
        for (var i = 0; i < this.GukList.length; i++) {
          if (this.Gukactive[i]) {
            cardId =
              cardId == ''
                ? this.GukList[i].storeShopCardRecordId + ''
                : cardId + ',' + (this.GukList[i].storeShopCardRecordId + '');
          }
        }
        this.cardId = cardId;
      }

      getMemberInfo().then((res) => {
      if (this.address === "") {
        this.$message.warning({
          message: '请先选择收货地址！',
        });
        return;
      }
        this.isLoading = false;
        let isManager = res.data.managerType == 1;
        if (isManager) {
          this.$refs.settlementMember.username = '';
          this.$refs.settlementMember.initData();
          this.$refs.settlementMember.dialogTableVisible = true;
        } else {
          this.doSubmitOrder();
        }
      });
    },
    //获取购物车ID
    getcartSkuId() {
      let delcartSkuId = [];
      this.$route.query.id.forEach((res) => {
        delcartSkuId.push(res.cartSkuId);
      });
      post('api/cart/deleteCartSome', {
        batchNumbers: delcartSkuId.toString(),
      }).then((res) => {});
    },
    doSubmitOrder() {
      if (this.jifen == 1) {
        this.orderSource = 4;
      }

      let buyerNotes = [];
      let data2 = this.$refs.settlementDetailedList.data;
      for(var index in data2){
        for(var index2 in data2[index].skuList){
          buyerNotes.push({
            orderTempId:data2[index].skuList[index2].orderTempId,
            buyerNote:data2[index].skuList[index2].buyerNote,
          })
        }
      }

      submitOrder({
        orderSource: this.orderSource,
        receiverId: this.address.receiverId,
        memberId: this.memberId,
        couponId: this.couponId,
        cardId: this.cardId,
        useScore: this.exchangeScore * this.num,
        buyerRemark:this.buyerRemark,
        deliveryMethod:this.deliveryMethod,
        buyerNotes:buyerNotes,//产品备注
        //storePostage:this.storePostage
      }).then((res) => {
        
        if (this.$route.query.id) {
          this.getcartSkuId();
        }
        if (res.code == 0) {
          if(this.jifen == 1){

          }else{
            // 执行订单创建后给客户推送确认订单创建
            post('api/wxMsgPush/sendOrderCreateWxMsg?orderId='+res.data.orderId+'').then((res) => {
            });
          }
          
          //积分购买
          // if (this.jifen == 1) {
          //   res.data['jifen'] = 1;
          //   res.data['exchangeScore'] = this.exchangeScore;
          //   res.data['skuId'] = this.skuId;
          //   res.data['spuId'] = this.spuId;
          //   res.data['num'] = this.num;
          // }

          this.price=this.sumPrice - this.couponPrice - this.cardPrice;

          this.$router.push({
            name: 'Zhuanzhang',
            params: {
              price: this.price,
            },
            query: {
              orderId: res.data.mergeOrderNo,
              istrue: '1',
              isBuy:res.data.isBuy,
              jifen:this.jifen
            },
          });
          //openPay(res.data.mergeOrderNo);
        } else if(res.code == 301){
          this.$message.warning({
            message: res.msg,
          });
        } else {
          this.$message.error({
            message: res.msg,
          });
        }
      });
    },
    setDeliveryMethod(deliveryMethod){
      this.deliveryMethod = deliveryMethod;
    },
  },
};
</script>
<style scoped>
.settlement-real {
  text-align: right;
}
.pay-wrapper {
  display: inline-block;
  padding: 20px;
  min-width: 350px;
  border: 2px solid #1950ff;
}
.settlement-real-item {
  margin-bottom: 5px;
}
.sum {
  font-weight: 600;
  color: #ee821f;
}
.pay-title {
  font-weight: bold;
  font-size: 14px;
}
.pay-text {
  font-size: 12px;
  color: #666;
}
</style>

<style lang="scss">
.settlement {
  .kong {
    background: #fff;
  }
}
</style>
<style lang="scss" scoped>
.settlement {
  width: 100%;
  min-height: 100%;
  padding-bottom: 1px;

  &-title {
    padding: 10px 0px;
    border-bottom: 2px solid #f5f5f5;
    font-family: 'Microsoft Yahei';
    font-family: '微软雅黑';
    font-size: 18px;
  }

  &-content {
    width: 1237px;
    min-height: 100%;
    margin: auto;

    &-line {
      // display: inline-block;
      width: 100%;
      height: 1px;
      background: #e9e9e9;
    }

    .settlement-rule {
      width: 100%;
      height: 104px;

      &-item {
        margin: 12px 0px;
        text-align: right;
        font-size: 16px;

        .text {
          display: inline-block;
          margin-right: 30px;
          text-align: right;
          font-weight: 600;
          color: #787878;
        }

        .money {
          display: inline-block;
          width: 100px;
          text-align: left;
          font-weight: 600;
          color: #555555;

          &:before {
            content: '￥';
          }
        }
      }
    }

    .button-content {
      text-align: right;

      .submit {
        font-size: 12px;
        background: #1850ff;
        border: 0px;
        border-radius: 6px;
        color: #fff;
        width: 100px;
        height: 31px;
        margin-top: 20px;
        margin-right: 30px;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
.settlement-delivery_method {
  margin: 10px 0px;
  width: 100%;

  .title {
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 20px;
    position: relative;

    &-add {
      position: absolute;
      right: 0px;
      border: 0px;
      background: transparent;
      color: #1950ff;
      padding: 0px;
      height: 100%;

      &:focus {
        outline: none;
      }
    }
  }
  .fangshi {
    display: flex;
  }
  .content {
    @import 'settlement-button';

    .settlement-button {
      margin-right: 10px;
    }
  }
}
</style>
