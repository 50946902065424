<template>
  <div class="settlement-invoice">
    <div class="title">
      <span>发票信息</span>
    </div>
    <div class="content">
      <div class="settlement-invoice-switch">
        <button class="settlement-button" :class="{'active':(!active)}" @click="handleInvoiceSwitch(false)">
          <span>不开票</span>
        </button>
        <button class="settlement-button" :class="{'active':active}" @click="handleInvoiceSwitch(true)">
          <span>开票</span>
        </button>
      </div>
      <div class="settlement-invoice-info" v-if="active">
        <div class="title">
          <span>电子发票信息</span>
        </div>
        <div class="content">
          <div class="input-content">
            <div class="input-item">
              <span class="lable">{{ $t('label.Order_number2') }} :</span>
              <input class="input" value="自动生成" disabled />
            </div>
            <div class="input-item">
              <span class="lable required">{{ $t('label.Invoice_type') }} :</span>
              <input class="input" value="" />
            </div>
            <div class="input-item">
              <span class="lable required">{{ $t('label.Invoice_content') }} :</span>
              <input class="input" value="" />
            </div>
            <div class="input-item">
              <span class="lable required">{{ $t('label.Header_type') }} :</span>
              <input class="input" value="" />
            </div>
            <div class="input-item">
              <span class="lable">{{ $t('label.Invoice_title') }} :</span>
              <input class="input" value="" />
            </div>
            <div class="input-item">
              <span class="lable required">收票人手机 :</span>
              <input class="input" value="" />
            </div>
          </div>
          <button class="input-butten">
            <span>确认</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        active: true
      };
    },
    methods: {
      handleInvoiceSwitch(bool) {
        this.active = bool;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .settlement-invoice {
    margin: 10px 0px;
    width: 100%;

    &-switch {
      height: 79px;
      width: 100%;
    }

    &-info {
      border: 1px solid #e8e8e8;

      .title {
        height: 42px;
        line-height: 42px;
        padding-left: 20px;
        background: #f2f7fd;
      }

      .content {
        height: 232px;
        margin: 0px 44px;

        .input-content {
          height: 166px;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fill, 330px);
          grid-gap: 0px 50px;

          .input-item {
            margin: auto;
            width: 330px;
            height: 45px;
            // background: brown;

            display: inline-flex;

            .lable {
              display: inline-block;
              min-width: 78px;
              height: 100%;
              line-height: 45px;
              margin-right: 24px;

              &.required{
                position: relative;
                &:before{
                  color:red;
                  content: "*";
                  position: absolute;
                  top: -2px;
                  left: -8px;
                }
              }
            }

            .input {
              flex: 100;
              width: 100%;
              background: transparent;
              border: 1px solid #e6e6e6;
              padding: 0px 9px;
            }
          }
        }

        .input-butten {
          border: 0px;
          background: transparent;
          width: 100px;
          height: 30px;
          border: 1px solid #1950ff;
          color: #1950ff;
          border-radius: 6px;
        }

      }
    }

    >.title {
      font-size: 12px;
      font-weight: 800;
      margin-bottom: 20px;
      position: relative;
    }

    >.content {
      width: 100%;
      @import "settlement-button";

      .settlement-button {
        margin-right: 10px;
      }
    }
  }
</style>