<template>
  <div class="settlement-detailed-list">
    <div class="title">
      <span>送货清单</span>
      <div class="order-wrapper">
        <el-row :gutter="2">
          <el-col :span="7">
            <div class="grid-content">店铺宝贝</div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content">商品属性</div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content">{{ $t('common.Mark_number') }}</div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content">单价</div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content">数量</div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content">小计</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">产品备注</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="content">
      <div class="wares">
        <div class="store-sku-wrapper" v-for="(store, i) in data" :key="i">
          <h4 class="store-title">{{ $t('common.shop') }}：{{ store.storeName }}</h4>
          <div class="sku-list">
            <el-row
              :gutter="2"
              class="sku-item"
              v-for="(sku, j) in store.skuList"
              :key="sku.skuId"
            >
              <el-col :span="7">
                <div class="sku-wrapper">
                  <Uimage :src="sku.img" class="default-image" />
                  <div class="sku-title">{{ sku.name }}</div>
                </div>
              </el-col>
              <el-col :span="3">
                <ul class="attr-list" v-if="sku.customType == 1">
                  <li>
                    <span class="attr-label">自定义气体:</span>
                    <div class="custom-wrapper">
                      <span
                        v-for="(item, index) in JSON.parse(sku.remark)
                          .component"
                        :key="index"
                        >{{ item.name }}:{{ item.value }}%<br
                      /></span>
                    </div>
                  </li>
                  <li
                    v-for="(item, index) in JSON.parse(sku.remark).other"
                    :key="index"
                  >
                    <span class="attr-label">{{ item.name }}：</span>
                    <div class="custom-wrapper">{{ item.value }}</div>
                  </li>
                </ul>
                <ul class="attr-list" v-else>
                  <li v-for="attr in sku.saleAttrCode">
                    {{ attr.attrName }}：<span v-html="attr.attrValue"></span>
                  </li>
                  <!-- <li v-if="sku.value != '0mol/mol'">确认含量：<apan v-html="sku.value"></apan></li> -->
                </ul>
              </el-col>
              <el-col :span="3">
                <div class="price">
                  {{ sku.skuNum }}
                </div>
              </el-col>
              <el-col :span="3">
                <div class="price">
                  {{ toDecimal2(sku.price) }}
                </div>
              </el-col>
              <el-col :span="3">
                <div class="num">
                  {{ sku.num }}
                </div>
              </el-col>
              <el-col :span="3">
                <div class="small-sum">{{ toDecimal2(sku.price*sku.num) }}</div>
              </el-col>
              <el-col :span="2">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="备注"
                  maxlength="15"
                  v-model="sku.buyerNote">
                </el-input>
              </el-col>
            </el-row>
          </div>
          <div class="store-sum-wrapper">
            店铺合计 <span v-if="store.storeFreePostage > store.storePrice"
              >(订单金额未满{{ store.storeFreePostage }}元，收取{{store.
                storePostage
              }}元运费)</span
            >
            <span class="store-sum">{{ toDecimal2(store.storePrice) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import detailed_list_store from './settlement-detailed-list-store';
import { toDecimal2 } from '@/utils/numberUtils.js';
export default {
  props: ['data', 'buyFrom'],
  data() {
    return {};
  },
  computed: {
    calcPrice() {
      let arr = [];
      for (let index in this.data) {
        let store = this.data[index];
        arr[index] = {
          storePriceSum: 0,
          skuPriceSum: [],
        };
        for (let j in store.skuList) {
          let sku = this.data[index].skuList[j];
          let price = sku.price;
          let num = sku.num;
          let sumPrice = toDecimal2(price * num);
          arr[index].skuPriceSum.push(sumPrice);
          arr[index].storePriceSum += parseFloat(sumPrice);
        }
        arr[index].storePriceSum = toDecimal2(arr[index].storePriceSum);
      }

      let sumPrice = 0;
      for (let index in arr) {
        let price = arr[index].storePriceSum;
        sumPrice += parseFloat(price);
      }

      this.$emit('sumPrice', toDecimal2(sumPrice));
      return arr;
    },
  },

  mounted() {},
  methods: {
    toDecimal2,
    ss(sku) {},
  },
  components: {
    'detailed-list-store': detailed_list_store,
  },
};
</script>

<style scoped>
.attr-label {
  float: left;
}
.custom-wrapper {
  margin-left: 70px;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.store-sku-wrapper {
  margin-bottom: 20px;
}
.sku-item {
  margin-bottom: 20px;
}
.sku-list {
  border-top: 1px dotted #80b2ff;
  padding: 10px 15px;
  background-color: #fbfcff;
  border-bottom: 1px dotted #ddd;
}
.store-sum-wrapper {
  text-align: right;
  background-color: #f2f7ff;
  padding: 3px 10px 3px 0;
  height: 45px;
  line-height: 45px;
  color: #666;
  font-size: 14px;
  border-bottom: 1px dotted #80b2ff;
}
.store-sum {
  font-weight: 600;
  color: #ee821f;
}
.store-title {
  margin-top: 10px;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 10px;
}
.default-image {
  height: 50px;
  width: 50px;
}
.attr-list {
  margin-left: 20px;
  min-height: 10px;
}
.attr-list > li {
  margin-bottom: 5px;
}
.sku-wrapper {
  display: flex;
}
.sku-title {
  vertical-align: top;
  margin-left: 20px;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  cursor: pointer;
}
.price,
.num,
.small-sum {
  text-align: center;
}
.price,
.num,
.small-sum,
.sku-title,
.attr-list > li {
  font-weight: normal;
  font-size: 12px;
  color: #666;
}

.grid-content {
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  border-bottom: 3px solid #b2d1ff;
}
</style>

<style lang="scss" scoped>
.settlement-detailed-list {
  margin: 20px 0px 0 0;
  width: 100%;

  .title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;

    .express {
      width: 422px;
      background: #f5f5f5;
      padding: 30px;

      &-title {
        font-size: 12px;
      }

      &-btn {
        margin: 10px 0px;
        @import 'settlement-button';
      }

      &-delivery {
        font-size: 12px;
        margin-top: 20px;

        &-title {
          color: #888888;
          display: inline-block;
        }

        &-text {
          display: inline-block;
          margin-left: 15px;

          &:before {
            content: '预计';
          }

          &:after {
            content: '前送达';
          }
        }
      }

      &-guarantee {
        &-item {
          .sel {
            display: inline-block;
            margin: 10px 20px;
            &.active {
              position: relative;
              &:before {
                content: '';
                position: absolute;
                width: 14px;
                height: 14px;
                left: -18px;
                top: 0px;
                bottom: 0px;
                margin: auto;
                background: transparent url('../../assets/settlement/ball.png');
                background-size: 100% 100%;
              }
            }
          }

          .text {
            color: #878787;
          }
        }
      }
    }

    .wares {
      width: 100%;
    }
  }
}
</style>
